import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-71d5a512"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_landscape_orientation_warning = _resolveComponent("landscape-orientation-warning")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    class: "fullheight",
    view: "lHh Lpr lFf"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_landscape_orientation_warning, {
        class: "absolute-center",
        id: "orientation-warning"
      }),
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_router_view)
      ])
    ]),
    _: 1
  }))
}